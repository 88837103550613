export const approveStatusType = [
  {
    name: 'All',
    id: 'all',
  },
  {
    name: 'Pending',
    id: 4,
  },
  {
    name: 'Rejected',
    id: 5,
  },
  {
    name: 'Terminate Requested',
    id: 7,
  },
];

export const genderList = [
  {
    label: 'Male',
    value: 'Male',
  },
  {
    label: 'Female',
    value: 'Female',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

export const bloodGroups = ['A(+ve)', 'A(-ve)', 'AB(+ve)', 'AB(-ve)', 'B(+ve)', 'B(-ve)', 'O(+ve)', 'O(-ve)'];

export const religionList = [
  { label: 'Islam', value: 'Islam' },
  { label: 'Hindu', value: 'Hindu' },
  { label: 'Buddha', value: 'Buddha' },
  { label: 'Christian', value: 'Christian' },
  { label: 'Others', value: 'Others' },
];

export const queryCachedTime = 5 * 60 * 1000;
export const staleTime = 0;

export const relations = ['Father', 'Mother', 'Brother', 'Sister', 'Wife', 'Others'];

export const dynamicBackgroundColor = {
  success: '#0CD66E',
  warning: '#FFAB00',
  error: '#FF5630',
  info: '#4F93F8',
};

export const typeWiseIcon = {
  success: 'eva:checkmark-circle-2-fill',
  warning: 'eva:alert-circle-fill',
  error: 'eva:alert-triangle-fill',
  info: 'eva:info-fill',
};

export const typeWiseText = {
  success: 'Success',
  warning: 'Warning',
  error: 'Delete',
  info: 'Info',
};

export const maritalStatus = [
  { label: 'Married', value: 'Married' },
  { label: 'Unmarried', value: 'Unmarried' },
];

export const idTypes = ['NID', 'Birth Certificate', 'Passport'];

export const mfsProviders = ['Bkash', 'Rocket', 'Nagad', 'Upay'];

export const USER_TYPE = [
  {
    id: 'org_mgt',
    name: 'Organization User',
  },
  {
    id: 'agency_mgt',
    name: 'Agency User',
  },
];

export const rejectedOrTerminatedCause = [
  'Attendance issues ',
  'Disciplinary issues ',
  'Incompetence, including lack of productivity or poor quality of work',
  'Insubordination and related issues such as dishonesty or breaking company rules',
  'Sexual harassment and other discriminatory behavior in the workplace',
  'Physical violence or threats against other employees',
];

export const BRAssignType = [
  { key: 1, value: 'All' },
  { key: 2, value: 'Assign' },
  { key: 3, value: 'Unassign' },
];

export const weekDaysObj = {
  Sunday: {
    checked: false,
  },
  Monday: {
    checked: false,
  },
  Tuesday: {
    checked: false,
  },
  Wednesday: {
    checked: false,
  },
  Thursday: {
    checked: false,
  },
  Friday: {
    checked: false,
  },
  Saturday: {
    checked: false,
  },
};

export const statusWiseColor = {
  Running: 'green',
  Scheduled: 'warning',
  Completed: 'blue',
};

export const queryType = [
  {
    value: 'RDS',
    label: 'RDS',
  },
  {
    value: 'BigQuery',
    label: 'BigQuery',
  },
];

export const smsPlatformType = [
  {
    value: 'Whatsapp',
    label: 'Whatsapp',
  },
  {
    value: 'Phone',
    label: 'Phone SMS',
  },
];

export const MONTH_OF_YEAR = [
  { id: 0, name: 'int-january' },
  { id: 1, name: 'int-february' },
  { id: 2, name: 'int-march' },
  { id: 3, name: 'int-april' },
  { id: 4, name: 'int-may' },
  { id: 5, name: 'int-june' },
  { id: 6, name: 'int-july' },
  { id: 7, name: 'int-august' },
  { id: 8, name: 'int-september' },
  { id: 9, name: 'int-october' },
  { id: 10, name: 'int-november' },
  { id: 11, name: 'int-december' },
];

export const WEEKEND = [
  { id: 0, name: 'int-sunday' },
  { id: 1, name: 'int-monday' },
  { id: 2, name: 'int-tuesday' },
  { id: 3, name: 'int-wednesday' },
  { id: 4, name: 'int-thursday' },
  { id: 5, name: 'int-friday' },
  { id: 6, name: 'int-saturday' },
];

export const CHART_BUILDING_DATA = [
  'Count',
  'Count Unique Values',
  'List Unique Values',
  'Sum',
  'Integer Sum',
  'Average',
  'Median',
  'Sample Variance',
  'Sample Standard Deviation',
  'Minimum',
  'Maximum',
  'First',
  'Last',
  'Sum over Sum',
  'Sum as Fraction of Total',
  'Sub as Fraction of Rows',
  'Sum as Fraction of Columns',
  'Count as Fraction of Total',
  'Count as Fraction of Rows',
  'Count as Fraction of Columns',
];

export const CHART_TYPE = [
  'Grouped Columns Chart',
  'Grouped Column Chart',
  'Stacked Column Chart',
  'Grouped Bar Chart',
  'Stacked Bar Chart',
  'Line Chart',
  'Dot Chart',
  'Area Chart',
  'Scatter Chart',
  'Multiple Pie Chart',
  'Table',
  'Table Heatmap',
  'Table Col Heatmap',
  'Table Row Heatmap',
  'Exportable TSV',
];

export const approvalType = [
  {
    id: 0,
    name: 'All',
  },
  {
    id: 1,
    name: 'Rejected',
  },
  {
    id: 2,
    name: 'Pending',
  },
  {
    id: 3,
    name: 'Approved',
  },
];

export const leaveType = [
  {
    id: 119,
    name: 'Sick Leave',
  },
  {
    id: 118,
    name: 'Casual Leave',
  },
];

export const pageSizeOptions = [10, 20, 25, 50, 100];

export const playbackOptions = [0.5, 0.75, 1, 1.25, 1.5];

export const REJECT_REASON = [
  'He is not a valid employee',
  'He is already in the system',
  'He is a nonchalant',
  'Other',
];
