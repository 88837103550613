import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';

import ScrollToTop from './components/ScrollToTop';
import Router from './routes';
import useOnline from 'hooks/useOnline';
import NoConnection from 'components/NoConnection';
import { AuthContextProvider } from 'contexts/Auth';
import { AppLoader } from 'components/AppComponent';

const queryClient = new QueryClient();

function App() {
  const online = useOnline();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorText: '#434D58',
          colorPrimary: '#005BD4',
          colorInfo: '#005BD4',
          colorLink: '#051367',
          fontSize: 14,
          borderRadius: 8,
          fontFamily: 'Work Sans',
        },
        components: {
          Button: {
            fontSize: 14,
            fontSizeLG: 15.32,
          },
          Input: {
            controlHeight: 40,
          },
          InputNumber: {
            controlHeight: 40,
          },
          DatePicker: {
            controlHeight: 40,
          },
          Select: {
            controlHeight: 40,
          },
        },
      }}>
      <Suspense fallback={<AppLoader />}>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <ScrollToTop />
            {online ? <Router /> : <NoConnection />}
          </AuthContextProvider>
        </QueryClientProvider>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
