import { Typography } from 'antd';
import dayjs from 'dayjs';
import { Outlet } from 'react-router-dom';

const { Text } = Typography;

const LogoOnlyLayout = () => {
  return (
    <>
      <div className="container">
        <Outlet />
      </div>

      <footer className="flex-center mt-2 mb-1">
        <Text>
          [{process.env.REACT_APP_STAGE}] Copyright © Prism CRM {dayjs().format('YYYY')}
        </Text>
      </footer>
    </>
  );
};

export default LogoOnlyLayout;
