import { AES, enc } from 'crypto-js';
import dayjs from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export const setItem = (type, key, value) => {
  try {
    type.setItem(key, AES.encrypt(value, process.env.REACT_APP_SECRET).toString());
    return true;
  } catch (error) {
    return false;
  }
};

export const getItem = (type, key, defaultValue) => {
  try {
    const encryptedValue = type.getItem(key);
    if (!encryptedValue) {
      return defaultValue;
    }
    const decryptedValue = AES.decrypt(encryptedValue, process.env.REACT_APP_SECRET).toString(enc.Utf8);
    return decryptedValue;
  } catch (error) {
    return defaultValue;
  }
};

export const decodeLoginData = (hash) => {
  const decryptedBytes = AES.decrypt(hash, process.env.REACT_APP_SECRETKEY);
  const decryptedData = decryptedBytes.toString(enc.Utf8);
  return decryptedData;
};

export const removeItem = (type, key) => {
  type.removeItem(key);
};

export const removeAll = (type) => {
  type.clear();
};

export const getFirstLetter = (name) => {
  if (!name) return false;

  return name.charAt(0).toUpperCase();
};

export const isAlphaNumericValue = (value) => {
  return value && /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/.test(value);
};

export const isValidEmail = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const findWeekFromDate = (currentDate, weeks) => {
  currentDate = dayjs(currentDate);

  const currentWeekObj = weeks.find((week) => {
    return dayjs(currentDate).isBetween(dayjs(week.startDate), dayjs(week.endDate), null, '[]');
  });

  return currentWeekObj;
};

/**
 * This function takes start date and end date as arguments and returns the
 * weeks between the start date and the end date containing the below object
 * {
 *  id: unique id,
 * startDate: start date,
 * endDate: end date,
 * name: name of the week like (2024-01-07 to 2024-01-14)
 * }
 * @param {string} startDate start date of the event (campaign start date)
 * @param {string} endDate  end date of the event (campaign end date)
 * @returns array containing weeks between startDate and endDate
 */
export const getWeeksBetweenTwoDates = (startDate, endDate) => {
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);
  const weeks = [];

  let currentWeek = dayjs(startDate).startOf('week');
  while (currentWeek <= endDate) {
    const endDateOfWeek = dayjs(currentWeek).endOf('week');

    weeks.push({
      id: crypto.randomUUID(),
      name: `${currentWeek.format('YYYY-MM-DD')} to ${endDateOfWeek.format('YYYY-MM-DD')}`,
      startDate: currentWeek.format('YYYY-MM-DD'),
      endDate: endDateOfWeek.format('YYYY-MM-DD'),
    });
    currentWeek = dayjs(currentWeek).add(1, 'week').startOf('week');
  }

  return weeks;
};

/**
 * This function takes start date and end date and returns an array of the dates between them
 * @param {string} startDate start date of the week
 * @param {string} endDate end date of the week
 * @returns array of dates in between
 */
export const getDatesOfAWeek = (startDate, endDate) => {
  startDate = dayjs(startDate);
  endDate = dayjs(endDate);
  const dates = [];

  let currentWeek = dayjs(startDate).startOf('week');
  while (currentWeek <= endDate) {
    const endDateOfWeek = dayjs(currentWeek).endOf('week');
    const datesOfWeek = [];

    let currentDate = dayjs(currentWeek);
    while (currentDate <= endDateOfWeek) {
      datesOfWeek.push(currentDate.format('YYYY-MM-DD'));
      currentDate = currentDate.add(1, 'day');
    }

    dates.push(datesOfWeek);
    currentWeek = dayjs(currentWeek).add(1, 'week').startOf('week');
  }

  return dates[0];
};

export const isColumnDateLessThanCurrentDate = (myDate) => {
  return dayjs(myDate).isBefore(dayjs().format('YYYY-MM-DD'));
};

export const createBlob = (data) => {
  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const { base64, parts } = data;
  let blob;
  for (let i = 0; i < parts; i++) {
    blob = new Blob([base64ToArrayBuffer(base64[i])]);
  }

  return blob;
};

export const validateFileSize = (file) => {
  const fileSize = file.size / 1024;
  return fileSize <= 500;
};

export const getScheduleStatusFromCurrentDate = (fromDate, toDate) => {
  const fromDateObj = new Date(fromDate);
  const toDateObj = toDate ? new Date(toDate) : null;
  const currentObj = new Date();

  if (toDateObj && currentObj > toDateObj) {
    return 'Completed';
  } else if (currentObj < fromDateObj) {
    return 'Scheduled';
  } else {
    return 'Running';
  }
};

export const cellData = (data) => (data ? data : '-');

export const objectToArray = (passingObj) => {
  const result = Object.keys(passingObj).map((key) => {
    return { label: key, value: passingObj[key] };
  });

  return result;
};

export const sortLocationsByName = (locations) => {
  if (!locations) return [];

  return locations.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};
