import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { func } from 'prop-types';
import { useLocation, Link } from 'react-router-dom';

import AppIcon from 'components/AppComponent/AppIcon';
import { hasAccess } from 'lib/AccessHelper';

const getItem = (label, key, icon, children, type) => {
  return {
    label,
    key,
    icon,
    children,
    type,
  };
};

const rootSubmenuKeys = [
  'user-manager',
  'report-manager',
  'dashboard-manager',
  'product-manager',
  'ff-manager',
  'role-manager',
  'hr-manager',
  'agency-manager',
  'campaign-manager',
  'material-manager',
  'notification-manager',
  'organization-manager',
  'audit-ai',
  'jml-manager',
];

const MenuTopics = ({ onClick }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const [path, setPath] = useState('');
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    setPath(location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const MenuItems = [
    getItem(<Link to="/manager/home"> {t('int-home')}</Link>, 'home', <AppIcon icon="eva:home-fill" />),
    hasAccess(251) &&
      getItem(t('int-report-manager'), 'report-manager', <AppIcon icon="mingcute:chart-line-fill" />, [
        hasAccess(118) &&
          getItem(t('int-custom-report'), 'custom-report', '', [
            getItem(<Link to="/report-manager/custom-report-view">{t('int-report-view')}</Link>, 'custom-report-view'),
            hasAccess(118) &&
              getItem(
                <Link to="/report-manager/custom-report-list">{t('int-report-list')}</Link>,
                'custom-report-list',
              ),
            hasAccess(119) &&
              getItem(
                <Link to="/report-manager/custom-report-create">{t('int-report-create')}</Link>,
                'custom-report-create',
              ),
          ]),
        hasAccess(158) &&
          getItem(t('int-dynamic-report'), 'dynamic-report', '', [
            hasAccess(158) &&
              getItem(
                <Link to="/report-manager/dynamic-report-list">{t('int-report-list')}</Link>,
                'dynamic-report-list',
              ),

            hasAccess(161) &&
              getItem(
                <Link to="/report-manager/dynamic-report-create">{t('int-report-create')}</Link>,
                'dynamic-report-create',
              ),
          ]),
        hasAccess(299) &&
          getItem('Whatsapp / SMS Report', 'whatsapp-sms-report', '', [
            hasAccess(300) &&
              getItem(
                <Link to="/report-manager/whatsapp-sms-report-list">{t('int-report-list')}</Link>,
                'whatsapp-sms-report-list',
              ),

            hasAccess(302) &&
              getItem(
                <Link to="/report-manager/whatsapp-sms-report-create">{t('int-report-create')}</Link>,
                'whatsapp-sms-report-create',
              ),
          ]),

        hasAccess(305) &&
          getItem('Excel Report Generation', 'excel-report-generation', '', [
            hasAccess(306) &&
              getItem(<Link to="/report-manager/excel-report-list">{t('int-report-list')}</Link>, 'excel-report-list'),

            hasAccess(308) &&
              getItem(
                <Link to="/report-manager/excel-report-create">{t('int-report-create')}</Link>,
                'excel-report-create',
              ),
          ]),

        hasAccess(159) &&
          getItem(
            <Link to="/report-manager/ff-contact-location-report">{t('int-ff-contact-location-report')}</Link>,
            'ff-contact-location-report',
          ),

        hasAccess(160) &&
          getItem(
            <Link to="/report-manager/ff-live-location-report">{t('int-ff-live-location-report')}</Link>,
            'ff-live-location-report',
          ),

        hasAccess(258) &&
          getItem(
            <Link to="/report-manager/attendance-summary-report">{t('int-attendance-report')}</Link>,
            'attendance-summary-report',
          ),
      ]),

    hasAccess(116) &&
      getItem(t('int-gds-dashboard'), 'dashboard-manager', <AppIcon icon="ic:round-pie-chart" />, [
        getItem(<Link to="/dashboard-manager/dashboard-view">{t('int-dashboard-view')}</Link>, 'dashboard-view'),
        hasAccess(124) &&
          getItem(<Link to="/dashboard-manager/dashboard-list">{t('int-dashboard-list')}</Link>, 'dashboard-list'),
        hasAccess(125) &&
          getItem(
            <Link to="/dashboard-manager/dashboard-create">{t('int-dashboard-create')}</Link>,
            'dashboard-create',
          ),
      ]),

    hasAccess(35) &&
      getItem(t('int-user-manager'), 'user-manager', <AppIcon icon="mdi:users" />, [
        hasAccess(40) && getItem(<Link to="/user-manager/user-list">{t('int-user')}</Link>, 'user-list', null),
        // hasAccess(41) &&
        // getItem(<Link to="/user-manager/user-create"> {t('int-user-create')}</Link>, 'user-create', null),
        // getItem(<Link to="/user-manager/user-create"> {t('int-user-create')}</Link>, 'user-create', null),
      ]),

    hasAccess(261) &&
      getItem('JML Manager', 'jml-manager', <AppIcon icon="fa-solid:user-tag" />, [
        hasAccess(262) && getItem(<Link to="/jml-manager/new-request">JML Request</Link>, 'new-request'),
        hasAccess(263) && getItem(<Link to="/jml-manager/request-list">Request List</Link>, 'request-list'),
        hasAccess(264) && getItem(<Link to="/jml-manager/approve-list">Approve List</Link>, 'approve-list'),
        hasAccess(272) && getItem(<Link to="/jml-manager/jml-users">JML Users</Link>, 'jml-users'),
      ]),

    hasAccess(105) &&
      getItem(t('int-ff-manager'), 'ff-manager', <AppIcon icon="ph:users-three-fill" />, [
        hasAccess(107) && getItem(<Link to="/ff-manager/ff-list">{t('int-ff-list')}</Link>, 'ff-list'),
        hasAccess(106) && getItem(<Link to="/ff-manager/ff-create">{t('int-create-ff')}</Link>, 'ff-create'),
        hasAccess(256) &&
          getItem(<Link to="/ff-manager/bulk-reassign">{t('int-bulk-reassign')}</Link>, 'bulk-reassign'),
      ]),

    hasAccess(129) &&
      getItem(t('int-hr-manager'), 'hr-manager', <AppIcon icon="fa-solid:users-cog" />, [
        (hasAccess(247) || hasAccess(248)) &&
          getItem(t('int-leave-management'), 'leave-management', '', [
            hasAccess(247) && getItem(<Link to="/hr-manager/leave">{t('int-leave')}</Link>, 'leave'),
            hasAccess(248) &&
              getItem(<Link to="/hr-manager/leave-request">{t('int-leave-request')}</Link>, 'leave-request'),
          ]),

        hasAccess(249) &&
          getItem(<Link to="/hr-manager/leave-approval">{t('int-leave-approval')}</Link>, 'leave-approval'),

        hasAccess(238) &&
          getItem(<Link to="/hr-manager/pending-ff-list">{t('int-recruitment-approval')}</Link>, 'pending-ff-list'),
      ]),

    hasAccess(36) &&
      getItem(t('int-role-manager'), 'role-manager', <AppIcon icon="mingcute:user-lock-fill" />, [
        hasAccess(46) && getItem(<Link to="/role-manager/role-list">{t('int-role-list')}</Link>, 'role-list'),
        hasAccess(47) && getItem(<Link to="/role-manager/role-create">{t('int-role-create')}</Link>, 'role-create'),
      ]),

    hasAccess(187) &&
      getItem(t('int-agency-manager'), 'agency-manager', <AppIcon icon="map:insurance-agency" />, [
        hasAccess(188) && getItem(<Link to="/agency-manager/agency-list">{t('int-agency-list')}</Link>, 'agency-list'),
        hasAccess(189) &&
          getItem(<Link to="/agency-manager/agency-create">{t('int-create-agency')}</Link>, 'agency-create'),
      ]),

    hasAccess(37) &&
      getItem(t('int-campaign-manager'), 'campaign-manager', <AppIcon icon="material-symbols:campaign-rounded" />, [
        hasAccess(76) &&
          getItem(<Link to="/campaign-manager/campaign-list">{t('int-campaign-list')}</Link>, 'campaign-list'),
        hasAccess(75) &&
          getItem(<Link to="/campaign-manager/campaign-create">{t('int-create-campaign')}</Link>, 'campaign-create'),
        hasAccess(252) &&
          getItem(<Link to="/campaign-manager/manpower-create">{t('int-manpower-create')}</Link>, 'manpower-create'),
        hasAccess(253) &&
          getItem(
            <Link to="/campaign-manager/manpower-calendar">{t('int-manpower-calender')}</Link>,
            'manpower-calendar',
          ),
        hasAccess(271) && getItem(<Link to="/campaign-manager/dialer-module">Dialer Module</Link>, 'dialer-module'),
      ]),

    hasAccess(163) &&
      getItem(t('int-material-manager'), 'material-manager', <AppIcon icon="mdi:material" />, [
        hasAccess(164) &&
          getItem(<Link to="/material-manager/material-list">{t('int-material-list')}</Link>, 'material-list'),
        hasAccess(165) &&
          getItem(<Link to="/material-manager/material-create">{t('int-material-create')}</Link>, 'material-create'),
      ]),

    hasAccess(83) &&
      getItem(t('int-product-manager'), 'product-manager', <AppIcon icon="fa6-solid:box-open" />, [
        hasAccess(151) && getItem(<Link to="/product-manager/sku-list">{t('int-product-list')}</Link>, 'sku-list'),
        hasAccess(152) &&
          getItem(<Link to="/product-manager/create-product">{t('int-create-product')}</Link>, 'create-product'),
      ]),

    hasAccess(39) &&
      getItem(t('int-notification-manager'), 'notification-manager', <AppIcon icon="mdi:notification-settings" />, [
        hasAccess(67) &&
          getItem(
            <Link to="/notification-manager/notification-list">{t('int-notification-list')}</Link>,
            'notification-list',
          ),
        hasAccess(68) &&
          getItem(
            <Link to="/notification-manager/notification-create">{t('int-notification-create')}</Link>,
            'notification-create',
          ),
      ]),

    hasAccess(86) &&
      getItem('Audit AI', 'audit-ai', <AppIcon icon="ri:headphone-fill" />, [
        hasAccess(274) && getItem(<Link to="/audit-ai/project-list">Project List</Link>, 'project-list'),
        hasAccess(275) && getItem(<Link to="/audit-ai/create-project">Create Project</Link>, 'create-project'),
      ]),

    hasAccess(38) &&
      getItem(t('int-org-manager'), 'organization-manager', <AppIcon icon="ic:round-settings" />, [
        hasAccess(239) &&
          getItem(
            <Link to="/organization-manager/device-management">{t('int-device-management')}</Link>,
            'device-management',
          ),

        hasAccess(268) &&
          getItem(
            <Link to="/organization-manager/remotely-block-device">Remotely Block Device</Link>,
            'remotely-block-device',
          ),

        hasAccess(241) &&
          getItem(<Link to="/organization-manager/office-setup">{t('int-office-setup')}</Link>, 'office-setup'),

        hasAccess(51) &&
          getItem(t('int-resources'), 'resource', '', [
            hasAccess(52) &&
              getItem(<Link to="/organization-manager/resource-list">{t('int-resource-list')}</Link>, 'resource-list'),
            hasAccess(99) &&
              getItem(
                <Link to="/organization-manager/resource-create">{t('int-create-resource')}</Link>,
                'resource-create',
              ),
          ]),

        hasAccess(77) &&
          getItem(t('int-locations'), 'location', '', [
            hasAccess(79) &&
              getItem(<Link to="/organization-manager/location-list">{t('int-location-list')}</Link>, 'location-list'),
            hasAccess(78) &&
              getItem(
                <Link to="/organization-manager/location-create">{t('int-create-location')}</Link>,
                'location-create',
              ),
          ]),

        hasAccess(102) &&
          getItem(t('int-hierarchy'), 'hierarchy', '', [
            hasAccess(102) &&
              getItem(
                <Link to="/organization-manager/hierarchy-list">{t('int-hierarchy-list')}</Link>,
                'hierarchy-list',
              ),
            hasAccess(103) &&
              getItem(
                <Link to="/organization-manager/hierarchy-create">{t('int-create-hierarchy')}</Link>,
                'hierarchy-create',
              ),
          ]),

        hasAccess(218) &&
          getItem(t('int-apk-distribution'), 'apk', '', [
            hasAccess(218) && getItem(<Link to="/organization-manager/apk-list">{t('int-apk-list')}</Link>, 'apk-list'),
            hasAccess(219) &&
              getItem(<Link to="/organization-manager/create-apk">{t('int-create-apk')}</Link>, 'create-apk'),
          ]),

        hasAccess(111) &&
          getItem(t('int-category'), 'category', '', [
            hasAccess(111) &&
              getItem(<Link to="/organization-manager/category-list">{t('int-category-list')}</Link>, 'category-list'),
            hasAccess(112) &&
              getItem(
                <Link to="/organization-manager/category-create">{t('int-create-category')}</Link>,
                'category-create',
              ),
          ]),
      ]),
  ];

  return (
    <Menu
      mode="inline"
      selectedKeys={[path.split('/')[2]]}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      onClick={() => onClick()}
      items={MenuItems}
    />
  );
};

export default MenuTopics;

MenuTopics.propTypes = {
  onClick: func,
};
