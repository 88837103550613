/**
 * This array oaf url is important to check the authorization of the url.
 * All protected urls should be here with their respected access code.
 * ###IMPORTANT###
 * For Urls that are dynamic like /report-manager/custom-report-update/:id
 * last parameter is not required here. /:id or /:param is needed. Only the base
 * url will be added.
 */

export const Accesses = [
  { url: '/report-manager/custom-report-view', access_code: 115 },
  { url: '/report-manager/custom-report-list', access_code: 118 },
  { url: '/report-manager/custom-report-create', access_code: 119 },
  { url: '/report-manager/custom-report-update', access_code: 122 },

  { url: '/report-manager/dynamic-report-details', access_code: 298 },
  { url: '/report-manager/dynamic-report-list', access_code: 298 },
  { url: '/report-manager/dynamic-report-create', access_code: 161 },
  { url: '/report-manager/dynamic-report-update', access_code: 242 },

  { url: '/report-manager/whatsapp-sms-report-list', access_code: 300 },
  { url: '/report-manager/whatsapp-sms-report-create', access_code: 302 },
  { url: '/report-manager/whatsapp-sms-report-update', access_code: 303 },

  { url: '/report-manager/excel-report-list', access_code: 306 },
  { url: '/report-manager/excel-report-create', access_code: 308 },
  { url: '/report-manager/excel-report-update', access_code: 309 },
  { url: '/report-manager/excel-report-build', access_code: 270 },

  { url: '/dashboard-manager', access_code: 116 },
  { url: '/dashboard-manager/dashboard-view', access_code: 116 },
  { url: '/dashboard-manager/dashboard-list', access_code: 124 },
  { url: '/dashboard-manager/dashboard-create', access_code: 125 },
  { url: '/dashboard-manager/dashboard-update', access_code: 127 },

  { url: '/user-manager/user-create', access_code: 41 },
  { url: '/user-manager/user-update', access_code: 43 },
  { url: '/user-manager/user-list', access_code: 40 },
  { url: '/user-manager/user-details', access_code: 42 },

  { url: '/role-manager/role-create', access_code: 47 },
  { url: '/role-manager/role-update', access_code: 48 },
  { url: '/role-manager/role-list', access_code: 46 },

  { url: '/product-manager/edit-product', access_code: 157 },
  { url: '/product-manager/create-product', access_code: 152 },
  { url: '/product-manager/sku-list', access_code: 151 },

  { url: '/campaign-manager/campaign-list', access_code: 76 },
  // { url: '/campaign-manager/cluster-mapping', access_code: 100 },
  { url: '/campaign-manager/route-plan', access_code: 100 },
  { url: '/campaign-manager/create-route-plan', access_code: 100 },
  { url: '/campaign-manager/update-route-plan', access_code: 100 },
  { url: '/campaign-manager/campaign-setting', access_code: 138 },
  { url: '/campaign-manager/campaign-config', access_code: 208 },
  { url: '/campaign-manager/assign-br', access_code: 142 },
  { url: '/campaign-manager/campaign-create', access_code: 75 },
  { url: '/campaign-manager/campaign-update', access_code: 139 },
  { url: '/campaign-manager/campaign-details', access_code: 192 },
  { url: '/campaign-manager/ff-limit', access_code: 231 },
  { url: '/campaign-manager/manpower-create', access_code: 252 },
  { url: '/campaign-manager/manpower-calendar', access_code: 253 },
  { url: '/campaign-manager/material-allocation', access_code: 246 },
  { url: '/campaign-manager/dialer-module', access_code: 271 },

  { url: '/organization-manager/resource-list', access_code: 52 },
  { url: '/organization-manager/resource-create', access_code: 99 },
  { url: '/organization-manager/resource-update', access_code: 53 },
  { url: '/organization-manager/settings', access_code: 38 },
  { url: '/organization-manager/theme-setup', access_code: 82 },

  { url: '/notification-manager/notification-list', access_code: 67 },
  { url: '/notification-manager/notification-create', access_code: 68 },
  { url: '/notification-manager/notification-details', access_code: 147 },
  { url: '/notification-manager/notification-update', access_code: 70 },

  { url: '/organization-manager/location-list', access_code: 79 },
  { url: '/organization-manager/location-create', access_code: 78 },
  { url: '/organization-manager/location-update', access_code: 81 },
  { url: '/organization-manager/add-children-location', access_code: 101 },

  { url: '/agency-manager/agency-list', access_code: 188 },
  { url: '/agency-manager/agency-create', access_code: 189 },
  { url: '/agency-manager/agency-update', access_code: 190 },

  // ff manager

  { url: '/ff-manager/ff-list', access_code: 107 },
  { url: '/ff-manager/ff-create', access_code: 106 },
  { url: '/ff-manager/ff-details', access_code: 108 },
  { url: '/ff-manager/ff-update', access_code: 109 },
  { url: '/ff-manager/bulk-reassign', access_code: 256 },

  // JML Manager
  { url: '/jml-manager/new-request', access_code: 262 },
  { url: '/jml-manager/request-list', access_code: 263 },
  { url: '/jml-manager/approve-list', access_code: 264 },
  { url: '/jml-manager/jml-users', access_code: 264 },
  { url: '/jml-manager/approve-details', access_code: 266 },
  { url: '/jml-manager/request-details', access_code: 272 },

  //Hierarchy Manager
  { url: '/organization-manager/hierarchy-list', access_code: 102 },
  { url: '/organization-manager/hierarchy-create', access_code: 103 },
  { url: '/organization-manager/hierarchy-update', access_code: 104 },

  // hr manager
  { url: '/hr-manager/pending-ff-list', access_code: 130 },
  { url: '/hr-manager/leave-approval', access_code: 249 },
  { url: '/hr-manager/leave', access_code: 247 },
  { url: '/hr-manager/leave-request', access_code: 248 },
  { url: '/hr-manager/hr-details', access_code: 238 },

  //Category Manager
  { url: '/organization-manager/category-list', access_code: 111 },
  { url: '/organization-manager/category-create', access_code: 112 },
  { url: '/organization-manager/category-update', access_code: 113 },
  { url: '/organization-manager/add-children-category', access_code: 131 },
  {
    url: '/material-manager/material-list',
    access_code: 164,
  },
  {
    url: '/material-manager/material-create',
    access_code: 165,
  },
  {
    url: '/material-manager/material-update',
    access_code: 166,
  },
  {
    url: '/material-manager/assign-material',
    access_code: 167,
  },

  // retail manager
  { url: '/retail-manager/landing', access_code: 85 },
  { url: '/retail-manager/retail-list', access_code: 201 },
  { url: '/retail-manager/retail-create', access_code: 202 },
  { url: '/retail-manager/retail-details', access_code: 205 },
  { url: '/retail-manager/retail-update', access_code: 203 },
  { url: '/retail-manager/program/program-list', access_code: 194 },
  { url: '/retail-manager/program/program-create', access_code: 196 },
  { url: '/retail-manager/program/program-update', access_code: 204 },
  { url: '/retail-manager/program/program-details', access_code: 197 },
  { url: '/retail-manager/program/ff-assignment', access_code: 195 },
  { url: '/retail-manager/invoice/invoice-list', access_code: 206 },
  { url: '/retail-manager/invoice/details', access_code: 207 },
  { url: '/retail-manager/pending-retailers', access_code: 207 },

  // live location reports
  { url: '/report-manager/ff-live-location-report', access_code: 160 },
  { url: '/report-manager/ff-contact-location-report', access_code: 159 },
  { url: '/report-manager/attendance-summary-report', access_code: 258 },
  { url: '/report-manager/attendance-details-report', access_code: 258 },

  // app distribution
  { url: '/organization-manager/apk-list', access_code: 218 },
  { url: '/organization-manager/create-apk', access_code: 219 },
  { url: '/organization-manager/device-management', access_code: 239 },
  { url: '/organization-manager/remotely-block-device', access_code: 268 },
  { url: '/organization-manager/office-setup', access_code: 241 },

  // retail promotions
  { url: '/retail-manager/promotion/list', access_code: 227 },
  { url: '/retail-manager/promotion/create/discount', access_code: 228 },
  { url: '/retail-manager/promotion/create/bundle', access_code: 228 },
  { url: '/retail-manager/promotion/update/discount', access_code: 229 },
  { url: '/retail-manager/promotion/update/bundle', access_code: 229 },

  // audit ai
  { url: '/audit-ai/project-list', access_code: 274 },
  { url: '/audit-ai/create-project', access_code: 275 },
  { url: '/audit-ai/audio-verification', access_code: 280 },
  { url: '/audit-ai/project/details', access_code: 274 },
  { url: '/audit-ai/project/update', access_code: 274 },
  { url: '/audit-ai/project/questions', access_code: 279 },
  { url: '/audit-ai/project/assign-auditor', access_code: 278 },
  { url: '/audit-ai/verification-analysis', access_code: 281 },
];
