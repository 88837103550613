import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import DashboardLayout from 'layouts/DashboardLayout';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import MobileAppLayout from 'layouts/MobileAppLayout';
import { protectRoutes } from 'lib/AccessHelper';

const Home = lazy(() => import('pages/Home'));

const NotAuthorized = lazy(() => import('pages/Others/NotAuthorized'));
const NotFound = lazy(() => import('pages/Others/NotFound'));

const Login = lazy(() => import('pages/Auth/Login'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'));
const VerifyOtp = lazy(() => import('pages/Auth/VerifyOtp'));
const VerifyMFA = lazy(() => import('pages/Auth/VerifyMFA'));
const AzureRedirect = lazy(() => import('pages/Auth/AzureRedirect'));

const FFList = lazy(() => import('pages/FFManager/FFList'));
const FFCreate = lazy(() => import('pages/FFManager/FFCreate'));
const FFUpdate = lazy(() => import('pages/FFManager/FFUpdate'));
const FFDetails = lazy(() => import('pages/FFManager/FFDetails'));
const BulkReassign = lazy(() => import('pages/FFManager/BulkReassign'));

const RoleList = lazy(() => import('pages/RoleManager/RoleList'));
const RoleCreate = lazy(() => import('pages/RoleManager/RoleCreate'));
const RoleUpdate = lazy(() => import('pages/RoleManager/RoleUpdate'));

const APKList = lazy(() => import('pages/OrganizationManager/APKDistribution/APKList'));
const CreateApk = lazy(() => import('pages/OrganizationManager/APKDistribution/CreateAPK'));
const DeviceManagement = lazy(() => import('pages/OrganizationManager/DeviceManagement'));
const RemotelyBlockDevice = lazy(() => import('pages/OrganizationManager/RemotelyBlockDevice'));
const ResourceList = lazy(() => import('pages/OrganizationManager/Resource/ResourceList'));
const ResourceCreate = lazy(() => import('pages/OrganizationManager/Resource/ResourceCreate'));
const ResourceUpdate = lazy(() => import('pages/OrganizationManager/Resource/ResourceUpdate'));
const ThemeSetup = lazy(() => import('pages/OrganizationManager/ThemeSetup'));
const OfficeSetup = lazy(() => import('pages/OrganizationManager/OfficeSetup'));

const CampaignList = lazy(() => import('pages/CampaignManager/CampaignList'));
const CampaignDetails = lazy(() => import('pages/CampaignManager/CampaignDetails'));
const CampaignCreate = lazy(() => import('pages/CampaignManager/CampaignCreate'));
const CampaignUpdate = lazy(() => import('pages/CampaignManager/CampaignUpdate'));
const CampaignSettings = lazy(() => import('pages/CampaignManager/CampaignSetting'));
const CampaignTargetSettings = lazy(() => import('pages/CampaignManager/CampaignTargetSetting'));
const AssignFF = lazy(() => import('pages/CampaignManager/AssignFF'));
const ClusterMapping = lazy(() => import('pages/CampaignManager/ClusterMapping'));
const FFLimit = lazy(() => import('pages/CampaignManager/CampaignFFLimit'));
const CreateManpower = lazy(() => import('pages/CampaignManager/ManpowerUtilization/CreateManpower'));
const ManpowerCalendar = lazy(() => import('pages/CampaignManager/ManpowerUtilization/ManpowerCalendar'));
const MaterialAllocation = lazy(() => import('pages/CampaignManager/MaterialAllocation'));
const DialerModule = lazy(() => import('pages/CampaignManager/DialerModule'));
const RoutePlanList = lazy(() => import('pages/CampaignManager/RoutePlan/RoutePlanList'));
const CreateRoutePlan = lazy(() => import('pages/CampaignManager/RoutePlan/CreateRoutePlan'));
const UpdateRoutePlan = lazy(() => import('pages/CampaignManager/RoutePlan/UpdateRoutePlan'));

const MaterialList = lazy(() => import('pages/MaterialManager/MaterialList'));
const MaterialCreate = lazy(() => import('pages/MaterialManager/AddMaterial'));
const MaterialUpdate = lazy(() => import('pages/MaterialManager/UpdateMaterial'));
const AssignMaterial = lazy(() => import('pages/MaterialManager/AssignMaterial'));

const AgencyList = lazy(() => import('pages/AgencyManager/AgencyList'));
const CreateAgency = lazy(() => import('pages/AgencyManager/CreateAgency'));
const UpdateAgency = lazy(() => import('pages/AgencyManager/UpdateAgency'));

const HRDetails = lazy(() => import('pages/HRManager/HRDetails'));
const RecruitmentApproval = lazy(() => import('pages/HRManager/LeaveApproval/index.jsx'));
const Leave = lazy(() => import('pages/HRManager/Leave'));
const LeaveRequest = lazy(() => import('pages/HRManager/LeaveRequest'));
const PendingFFList = lazy(() => import('pages/HRManager/HRList'));

const UserUpdate = lazy(() => import('pages/UserManager/UserUpdate'));
const UserDetails = lazy(() => import('pages/UserManager/UserDetails'));
const UserCreate = lazy(() => import('pages/UserManager/UserCreate'));
const UserList = lazy(() => import('pages/UserManager/UserList'));

const NewJMLRequest = lazy(() => import('pages/JMLManager/NewJMLRequest'));
const RequestList = lazy(() => import('pages/JMLManager/RequestList'));
const ApproveList = lazy(() => import('pages/JMLManager/ApproveList'));
const ApproveDetails = lazy(() => import('pages/JMLManager/ApproveDetails'));
const RequestDetails = lazy(() => import('pages/JMLManager/RequestDetails'));
const JMLUsers = lazy(() => import('pages/JMLManager/JMLUsers'));

const AddChildLocation = lazy(() => import('pages/LocationManager/AddChildLocation'));
const LocationCreate = lazy(() => import('pages/LocationManager/LocationCreate'));
const LocationList = lazy(() => import('pages/LocationManager/LocationList'));
const LocationUpdate = lazy(() => import('pages/LocationManager/LocationUpdate'));

const HierarchyList = lazy(() => import('pages/HierarchyManager/HierarchyList'));
const HierarchyCreate = lazy(() => import('pages/HierarchyManager/HierarchyCreate'));
const HierarchyUpdate = lazy(() => import('pages/HierarchyManager/HierarchyUpdate'));

const NotificationCreate = lazy(() => import('pages/NotificationManager/NotificationCreate'));
const NotificationList = lazy(() => import('pages/NotificationManager/NotificationList'));
const NotificationUpdate = lazy(() => import('pages/NotificationManager/NotificationEdit'));
const NotificationDetails = lazy(() => import('pages/NotificationManager/NotificationDetails'));

const CategoryList = lazy(() => import('pages/CategoryManager/CategoryList'));
const CategoryCreate = lazy(() => import('pages/CategoryManager/CategoryCreate'));
const AddChildCategory = lazy(() => import('pages/CategoryManager/AddChildCategory'));
const CategoryUpdate = lazy(() => import('pages/CategoryManager/CategoryUpdate'));

const EditSKU = lazy(() => import('pages/ProductManager/EditSKU'));
const CreateProduct = lazy(() => import('pages/ProductManager/CreateProduct'));
const SKUList = lazy(() => import('pages/ProductManager/SKUList'));

const DashboardList = lazy(() => import('pages/DashboardManager/DashboardList'));
const Dashboards = lazy(() => import('pages/DashboardManager/Dashboards'));
const DashboardCreate = lazy(() => import('pages/DashboardManager/CreateDashboard'));
const DashboardUpdate = lazy(() => import('pages/DashboardManager/UpdateDashboard'));
const SingleDashboard = lazy(() => import('pages/DashboardManager/SingleDashboard'));

const ReportView = lazy(() => import('pages/ReportManager/CustomReport/ReportView'));
const ReportList = lazy(() => import('pages/ReportManager/CustomReport/ReportList'));
const ReportCreate = lazy(() => import('pages/ReportManager/CustomReport/ReportCreate'));
const ReportUpdate = lazy(() => import('pages/ReportManager/CustomReport/ReportUpdate'));

const DynamicReportCreate = lazy(() => import('pages/ReportManager/DynamicReport/ReportCreate'));
const DynamicReportList = lazy(() => import('pages/ReportManager/DynamicReport/ReportList'));
const DynamicReportDetails = lazy(() => import('pages/ReportManager/DynamicReport/ReportDetails'));
const UpdateDynamicReport = lazy(() => import('pages/ReportManager/DynamicReport/ReportUpdate'));

const WhatsappSMSReportCreate = lazy(() => import('pages/ReportManager/WhatsappOrSMSReport/WhatsappSMSReportCreate'));
const WhatsappSMSReportList = lazy(() => import('pages/ReportManager/WhatsappOrSMSReport/WhatsappSMSReportList'));
const WhatsappSMSReportUpdate = lazy(() => import('pages/ReportManager/WhatsappOrSMSReport/WhatsappSMSReportUpdate'));

const ExcelReportList = lazy(() => import('pages/ReportManager/ExcelReport/ExcelReportList'));
const ExcelReportCreate = lazy(() => import('pages/ReportManager/ExcelReport/ExcelReportCreate'));
const ExcelReportBuild = lazy(() => import('pages/ReportManager/ExcelReport/ExcelReportBuild'));
const ExcelReportUpdate = lazy(() => import('pages/ReportManager/ExcelReport/ExcelReportUpdate'));

const FFContactLocationReport = lazy(() => import('pages/ReportManager/FFContactLocationReport'));
const FFLiveLocationReport = lazy(() => import('pages/ReportManager/FFLiveLocationReport'));
const AttendanceSummaryReport = lazy(() => import('pages/ReportManager/Attendance/AttendanceSummary'));
const AttendanceDetailsReport = lazy(() => import('pages/ReportManager/Attendance/AttendanceDetails'));

const CreateProject = lazy(() => import('pages/AuditAI/Projects/CreateProject'));
const ProjectList = lazy(() => import('pages/AuditAI/Projects/ProjectList'));
const AudioVerification = lazy(() => import('pages/AuditAI/AudioVerification'));
const ProjectDetails = lazy(() => import('pages/AuditAI/Projects/ProjectDetails'));
const ProjectUpdate = lazy(() => import('pages/AuditAI/Projects/ProjectUpdate'));
const ProjectQuestions = lazy(() => import('pages/AuditAI/Questions'));
const AssignAuditor = lazy(() => import('pages/AuditAI/Projects/AssignAuditor'));
const VASupDashboard = lazy(() => import('pages/AuditAI/VerificationAnalysis'));

const Security = lazy(() => import('pages/Profile/Security'));

const AppDashboard = lazy(() => import('pages/MobileApp/AppDashboard'));

const Router = () => {
  return useRoutes([
    //public routes
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
          index: true,
        },
      ],
    },
    {
      path: '/auth',
      element: <LogoOnlyLayout />,
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'reset-password',
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: 'verify-otp',
          element: (
            <GuestGuard>
              <VerifyOtp />
            </GuestGuard>
          ),
        },
        {
          path: 'verify-mfa',
          element: (
            <GuestGuard>
              <VerifyMFA />
            </GuestGuard>
          ),
        },
        {
          path: 'redirect',
          element: (
            <GuestGuard>
              <AzureRedirect />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/mobile-app',
      element: <MobileAppLayout />,
      children: [
        {
          path: 'dashboard',
          element: <AppDashboard />,
        },
      ],
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
    {
      path: '/manager',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'home',
          element: <Home />,
        },
        {
          path: 'not-authorized',
          element: <NotAuthorized />,
        },
        {
          path: 'security',
          element: <Security />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
        {
          path: '404',
          element: <NotFound />,
        },
      ],
    },

    //protected routes
    ...protectRoutes([
      {
        path: '/report-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'custom-report-view',
            element: <ReportView />,
          },
          {
            path: 'custom-report-list',
            element: <ReportList />,
          },
          {
            path: 'custom-report-create',
            element: <ReportCreate />,
          },
          {
            path: 'custom-report-update/:id',
            element: <ReportUpdate />,
          },

          {
            path: 'dynamic-report-create',
            element: <DynamicReportCreate />,
          },
          {
            path: 'dynamic-report-update/:id',
            element: <UpdateDynamicReport />,
          },
          {
            path: 'dynamic-report-list',
            element: <DynamicReportList />,
          },
          {
            path: 'dynamic-report-details/:id',
            element: <DynamicReportDetails />,
          },
          {
            path: 'ff-contact-location-report',
            element: <FFContactLocationReport />,
          },
          {
            path: 'ff-live-location-report',
            element: <FFLiveLocationReport />,
          },
          {
            path: 'attendance-summary-report',
            element: <AttendanceSummaryReport />,
          },
          {
            path: 'attendance-details-report/:id',
            element: <AttendanceDetailsReport />,
          },
          {
            path: 'whatsapp-sms-report-list',
            element: <WhatsappSMSReportList />,
          },
          {
            path: 'whatsapp-sms-report-create',
            element: <WhatsappSMSReportCreate />,
          },
          {
            path: 'whatsapp-sms-report-update/:id',
            element: <WhatsappSMSReportUpdate />,
          },
          {
            path: 'excel-report-list',
            element: <ExcelReportList />,
          },
          {
            path: 'excel-report-create',
            element: <ExcelReportCreate />,
          },
          {
            path: 'excel-report-update/:id',
            element: <ExcelReportUpdate />,
          },
          {
            path: 'excel-report-build/:id',
            element: <ExcelReportBuild />,
          },
        ],
      },
      {
        path: '/dashboard-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'dashboard-view',
            element: <Dashboards />,
          },
          {
            path: 'dashboard-list',
            element: <DashboardList />,
          },
          {
            path: 'dashboard-create',
            element: <DashboardCreate />,
          },
          {
            path: 'dashboard-update/:id',
            element: <DashboardUpdate />,
          },
          {
            path: 'dashboard-view/:id',
            element: <SingleDashboard />,
          },
        ],
      },
      {
        path: '/user-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'user-create',
            element: <UserCreate />,
          },
          {
            path: 'user-update/:param',
            element: <UserUpdate />,
          },
          {
            path: 'user-list',
            element: <UserList />,
          },
          {
            path: 'user-details/:param',
            element: <UserDetails />,
          },
        ],
      },
      {
        path: '/jml-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'new-request',
            element: <NewJMLRequest />,
          },
          {
            path: 'request-list',
            element: <RequestList />,
          },
          {
            path: 'jml-users',
            element: <JMLUsers />,
          },
          {
            path: 'approve-list',
            element: <ApproveList />,
          },
          {
            path: 'approve-details/:id',
            element: <ApproveDetails />,
          },
          {
            path: 'request-details/:id',
            element: <RequestDetails />,
          },
        ],
      },
      {
        path: '/ff-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'ff-list',
            element: <FFList />,
          },
          {
            path: 'ff-create',
            element: <FFCreate />,
          },
          {
            path: 'ff-update/:param',
            element: <FFUpdate />,
          },
          {
            path: 'ff-details/:param',
            element: <FFDetails />,
          },
          {
            path: 'bulk-reassign',
            element: <BulkReassign />,
          },
        ],
      },
      {
        path: '/role-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'role-list',
            element: <RoleList />,
          },
          {
            path: 'role-create',
            element: <RoleCreate />,
          },
          {
            path: 'role-update/:id',
            element: <RoleUpdate />,
          },
        ],
      },
      {
        path: '/hr-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'pending-ff-list',
            element: <PendingFFList />,
          },
          {
            path: 'leave',
            element: <Leave />,
          },
          {
            path: 'leave-approval',
            element: <RecruitmentApproval />,
          },
          {
            path: 'leave-request',
            element: <LeaveRequest />,
          },
          {
            path: 'hr-details/:id',
            element: <HRDetails />,
          },
        ],
      },
      {
        path: '/campaign-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'campaign-list',
            element: <CampaignList />,
          },
          {
            path: 'campaign-create',
            element: <CampaignCreate />,
          },
          {
            path: 'campaign-update/:id',
            element: <CampaignUpdate />,
          },
          {
            path: 'campaign-details/:id',
            element: <CampaignDetails />,
          },
          {
            path: 'campaign-setting',
            element: <CampaignSettings />,
          },
          {
            path: 'campaign-config/:id',
            element: <CampaignTargetSettings />,
          },
          {
            path: 'assign-br/:id',
            element: <AssignFF />,
          },
          {
            path: 'cluster-mapping/:id',
            element: <ClusterMapping />,
          },
          {
            path: 'ff-limit/:id',
            element: <FFLimit />,
          },
          {
            path: 'material-allocation/:id',
            element: <MaterialAllocation />,
          },
          {
            path: 'manpower-create',
            element: <CreateManpower />,
          },
          {
            path: 'manpower-calendar',
            element: <ManpowerCalendar />,
          },
          {
            path: 'dialer-module',
            element: <DialerModule />,
          },
          {
            path: 'route-plan/:campaignId',
            element: <RoutePlanList />,
          },
          {
            path: 'create-route-plan/:campaignId',
            element: <CreateRoutePlan />,
          },
          {
            path: 'update-route-plan/:campaignId',
            element: <UpdateRoutePlan />,
          },
        ],
      },
      {
        path: '/material-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'material-list',
            element: <MaterialList />,
          },
          {
            path: 'material-create',
            element: <MaterialCreate />,
          },
          {
            path: 'material-update/:id',
            element: <MaterialUpdate />,
          },
          {
            path: 'assign-material/:id',
            element: <AssignMaterial />,
          },
        ],
      },
      {
        path: '/notification-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'notification-list',
            element: <NotificationList />,
          },
          {
            path: 'notification-create',
            element: <NotificationCreate />,
          },
          {
            path: 'notification-update/:id',
            element: <NotificationUpdate />,
          },
          {
            path: 'notification-details/:id',
            element: <NotificationDetails />,
          },
          {
            path: 'campaign-setting',
            element: <CampaignSettings />,
          },
          {
            path: 'campaign-config/:id',
            element: <CampaignTargetSettings />,
          },
          {
            path: 'assign-br/:id',
            element: <AssignFF />,
          },
          {
            path: 'cluster-mapping/:id',
            element: <ClusterMapping />,
          },
          {
            path: 'ff-limit/:id',
            element: <FFLimit />,
          },
        ],
      },
      {
        path: '/audit-ai',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'project-list',
            element: <ProjectList />,
          },
          {
            path: 'create-project',
            element: <CreateProject />,
          },
          {
            path: 'audio-verification/:id',
            element: <AudioVerification />,
          },
          {
            path: 'project/details/:id',
            element: <ProjectDetails />,
          },
          {
            path: 'project/update/:id',
            element: <ProjectUpdate />,
          },
          {
            path: 'project/questions/:id',
            element: <ProjectQuestions />,
          },
          {
            path: 'project/assign-auditor/:id',
            element: <AssignAuditor />,
          },
          {
            path: 'verification-analysis/:id',
            element: <VASupDashboard />,
          },
        ],
      },
      {
        path: '/organization-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'resource-list',
            element: <ResourceList />,
          },
          {
            path: 'resource-create',
            element: <ResourceCreate />,
          },
          {
            path: 'resource-update/:id',
            element: <ResourceUpdate />,
          },
          {
            path: 'location-list',
            element: <LocationList />,
          },
          {
            path: 'location-create',
            element: <LocationCreate />,
          },
          {
            path: 'location-update/:id',
            element: <LocationUpdate />,
          },
          {
            path: 'add-children-location',
            element: <AddChildLocation />,
          },
          {
            path: 'apk-list',
            element: <APKList />,
          },
          {
            path: 'create-apk',
            element: <CreateApk />,
          },
          {
            path: 'device-management',
            element: <DeviceManagement />,
          },
          {
            path: 'remotely-block-device',
            element: <RemotelyBlockDevice />,
          },
          {
            path: 'theme-setup',
            element: <ThemeSetup />,
          },
          {
            path: 'category-list',
            element: <CategoryList />,
          },
          {
            path: 'category-create',
            element: <CategoryCreate />,
          },
          {
            path: 'category-update/:id',
            element: <CategoryUpdate />,
          },
          {
            path: 'hierarchy-list',
            element: <HierarchyList />,
          },
          {
            path: 'hierarchy-create',
            element: <HierarchyCreate />,
          },
          {
            path: 'hierarchy-update/:id',
            element: <HierarchyUpdate />,
          },
          {
            path: 'add-children-category',
            element: <AddChildCategory />,
          },
          {
            path: 'office-setup',
            element: <OfficeSetup />,
          },
        ],
      },
      {
        path: '/agency-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'agency-list',
            element: <AgencyList />,
          },
          {
            path: 'agency-create',
            element: <CreateAgency />,
          },
          {
            path: 'agency-update/:id',
            element: <UpdateAgency />,
          },
        ],
      },
      {
        path: '/product-manager',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'edit-product/:id',
            element: <EditSKU />,
          },
          {
            path: 'sku-list',
            element: <SKUList />,
          },
          {
            path: 'create-product',
            element: <CreateProduct />,
          },
        ],
      },
    ]),
  ]);
};

export default Router;
